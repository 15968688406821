<template>    
    <img src="@/assets/logo1.jpg" class="img">       
    <img src="@/assets/logo2.jpg" class="img">
    <img src="@/assets/logo3.png">
    <div class="post">         
        <h2>Посібники підготовлено у рамках виконання Проекту 
            «Відродження переміщених університетів: посилення 
            конкурентоспроможності, підтримка громад» (REDU) 
            (2020-2024 роки)        
        </h2>   
    </div>     
</template>

<style scoped>
.img{    
    margin-bottom: .1rem;
    width: 15%;    
    text-align: justify;
    padding: 20px;    
}
img{    
    margin-bottom: .1rem;
    width: 5%;    
    text-align: justify;
    padding: 20px;
}
.post{    
    width: 80%;
    margin-left: 10%;   
}
</style>