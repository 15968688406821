<template>                     
    <input type="text" placeholder="пошук" v-model="pFiltr">            
</template>

<script> /* eslint-disable */

import { mapMutations } from 'vuex';

export default{  
    data(){
        return {
            pFiltr : ''
        }
    }, 
    methods: mapMutations(["changeFiltr"]),        
    watch:{
        pFiltr(nVal) {
            this.changeFiltr(nVal);            
        }
    }
}
</script>

<style scoped>
input{    
    width: 15%;
    font-size: 14pt;
    float: right;
    margin: -40px 10px;
    padding: 5px 0 5px 10px;
}
</style>


