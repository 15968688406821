<template>     
    <div class="book" v-for="book in filtrBooks" :key="book.id">        
        <img            
            :src="book.src"
            @click="openLink(book.link)"
        >
        <h2 class="avtor">{{ book.avtor }}</h2>
        <h2 v-html="book.name" class="name"></h2>
        <p v-html="book.body" ></p>        
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default{    
    computed:   mapGetters(["allBooks", "filtrBooks"]),
    methods:  {
        ...mapActions(["openLink"])
    }         
}
</script>

<style scoped>
.book{   
    /* border: 1px solid #ccc; */
    border-radius: 15px;    
    width: 80%;    
    text-align: justify;
    padding: 20px;
    overflow: hidden;
    font-size: 13pt;
    box-shadow: 1px 0px 9px 0px #1b1b20;
    /* transform: translate(-50%, -50%); */
    margin: 1rem auto;  
    animation: bounce-in .5s;      
}
@keyframes bounce-in {
  0% {
    transform:         
        scale(0);
  }
  50% {
    transform: 
        scale(1.3);       
  }
  100% {
    transform:         
        scale(1);
  }
}
img{    
    width: calc(10% + 50px);
    box-shadow: 1px 4px 10px 2px #8275da;
    float: left;
    margin: 20px 15px;
}
img:hover {
    transform: scale(1.05);
    box-shadow: 1px 4px 15px 5px #329cda;
}
.avtor{
    color: #5689a0;
}
.book:hover {   
    background-color: #d9e1d142;     
}
.book:hover .name {    
    text-shadow: 4px 2px 12px #329cda;
}

</style>