<template>
  <div id="app">   
    <CCaption/> 
    <CFind/>
    <hr>    
    <VTablView/>   
  </div>    
</template>

<script>
import VTablView  from "@/views/VTablView";
import CCaption   from '@/components/CCaption';
import CFind      from './components/CFind.vue';

export default {
  components: {
    VTablView, 
    CCaption,
    CFind    
  },
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
.keyword{
    color:#FF0000; 
    background: #72b8b3;
}
</style>
