/* eslint-disable */
export default {
  actions: {
    openLink({}, link) {
      const host = "https://timetable.lond.lg.ua/redu/";
      window.open(`${host}${link}`, "_blank");
    },
  },
  mutations: {
    changeFiltr(state, val) {
      state.vFiltr = val;
    },
  },
  state: {
    vFiltr: "",
    books: [
      {
        id: 1,
        name: "Маркетингові комунікації",
        avtor: "І. Сафронська",
        body: `Навчальний посібник «Маркетингові комунікації» присвячено актуальним питанням налагодженню ефективного маркетингового комунікаційного процесу. Навчальна дисципліна «Маркетингові комунікації», є обов’язковою компонентою освітньо-професійної програми «Маркетинг» і спрямована на вивчення теоретичних основ маркетингових комунікацій, передбачає формування практичних навичок  використання комунікаційних технологій у маркетингу.
                Навчальний посібник «Маркетингові комунікації» складається з двох частин: «Маркетингові комунікації та їх складові» і «Сучасні аспекти комунікацій в маркетингу», які представлені 8 темами, зміст яких відповідає робочій програмі дисципліни та силабусу. Теоретична складова по кожній темі структурована за розділами: поняття та їх визначення, основні  теоретичні положення та питання для самоконтролю. Практична складова представлена завданнями, ситуаційними задачами, кейсами та тестами. Посібник наповнений активними посиланнями на інтернет ресурси (статті, лекції, курси, презентації). Все це дозволяє здобувачу освіти набути теоретичних знань та сформувати практичні навички з оволодіння інструментами маркетингових комунікацій.
                Посібник призначено для здобувачів вищої освіти ОП «Маркетинг» (рівень бакалавр).
                `,
        src: require("@/assets/b1.jpg"),
        link: "1_book/mobile/index.html",
      },
      {
        id: 2,
        name: "Публічні закупівлі",
        avtor: "І. Кушал",
        body: `Навчальний  посібник  розроблений  відповідно  до  навчальної  програми  дисципліни  «Публічні 
                закупівлі» і включає розгляд сьомі тем: сутність та основні принципи здійснення публічних закупівель в 
                Україні;  етапи  планування  та  реалізації  процедур  закупівель  з  використанням  електронної  системи 
                публічних  закупівель  Prozorro;  процедури  закупівлі,  їх  види  та  характеристика;  методичні  засади 
                підготовки та затвердження тендерної документації; договір та звіт про виконання договору про закупівлю 
                в  електронній  формі,  особливості  закупівель  товарів,  робіт  і  послуг  та  їх  моніторинг  за  допомогою 
                аналітичних  інструментів  у  сфері  публічних  закупівель;  державне  регулювання  у  сфері  публічних 
                закупівель;  громадський  контроль  у  сфері  публічних  закупівель,  онлайн-платформа  DOZORRO  і  є 
                керівництвом  для  набуття  фахових  навичок  у  сфері  публічних  закупівель.  Кожна  тема  містить  основні 
                поняття  і  їх  визначення,  стислі  теоретичні  відомості,  практичні  завдання  (індивідуальні  розрахункові 
                завдання), а також питання для самоконтролю та тести. До структури посібника також входять додаткові 
                теоретичні  відомості,  необхідні  довідні  дані  та  посилання  на  інтернет  ресурси,  що  дозволяє  здобувачу 
                освіти  сформувати  практичні  навички  та  уявлення  у  сфері  публічних  закупівель,  що  доповніть  їх 
                професійність та сприятиме розвитку. 
                Посібник призначено для студентів, аспірантів і викладачів вищих навчальних закладів, а також для 
                суб’єктів  господарювання  державного  і  приватного  сектору,  громадським  організаціям  контролю,  що 
                цікавляться проблемами у сфері публічних закупівель.        
                `,
        src: require("@/assets/b2.jpg"),
        link: "2_book/mobile/index.html",
      },
      {
        id: 3,
        name: "Аналітична хімія та інструментальні методи аналізу",
        avtor: "О. Захарова",
        body: `Навчальна дисципліна «Аналітична хімія та інструментальні методи аналізу» належить до переліку дисциплін з циклу професійної підготовки студентів за освітньої-професійною програмою «Хімічні технології та інженерія» і спрямована на вивчення теоретичних основ аналітичної хімії та оволодіння сучасними інструментальними методами аналізу, передбачає формування умінь застосування одержаних знань для вивчення спеціальних дисциплін та у професійній діяльності. 
                Навчальний посібник з дисципліни «Аналітична хімія та інструментальні методи аналізу» охоплює два розділи: теоретичні основи аналітичної хімії та кількісний хімічний аналіз  і є практичним керівництвом для набуття навичок розв’язання задач з. Посібник охоплює 14 тем, кожна тема містить стислі теоретичні відомості, приклади розв’язання задач, практичні завдання (індивідуальні розрахункові завдання), а також питання для самоконтролю та тести. До структури посібника також входять додаткові теоретичні відомості, необхідні довідні дані та посилання на інтернет ресурси, що дозволяє здобувачу освіти сформувати практичні навички та уявлення про роботу в хімічній лабораторії.
                Посібник призначено для здобувачів вищої освіти ОП «Хімічні технології та інженерія» (рівень бакалавр).
                `,
        src: require("@/assets/b3.jpg"),
        link: "3_book/mobile/index.html",
      },
      {
        id: 4,
        name: "Державний фінансовий контроль",
        avtor: "Ю. Клюс",
        body: `В навчальному посібнику викладено лекційний та практичний матеріал до основних питань курсу
                «Державний фінансовий контроль». Матеріал посібника викладено на основі світового досвіду
                формування державних систем фінансового контролю та з урахуванням положень вітчизняних діючих
                законодавчих і нормативних чинників.
                Розгляд наукових надбань загальної теорії контролю, основ методології та організації державного
                фінансового контролю в Україні систематизовано у тематичному розрізі. Весь спектр тем вказує на
                змістовну насиченість, наявність посилань та витягів з необхідної кількості чинних нормативних і
                законодавчих актів сфери державного фінансового контролю в Україні.
                Навчальний посібник розрахований, передусім, на здобувачів вищої освіти ОП «Облік і
                оподаткування» (рівень магістр), а також викладачів економічних спеціальностей вищих навчальних
                закладів країни, аспірантів, науковців, слухачів післявузівської освіти і системи додаткової професійної
                освіти. Книга є корисною також для працівників державних і контролюючих установ, керівників
                господарюючих суб’єктів, їх структурних підрозділів і служб управління, фахівців у сферах обліку і контролю
                державних фінансів.`,
        src: require("@/assets/b4.jpg"),
        link: "4_book/mobile/index.html",
      },
      {
        id: 5,
        name: "Розрахунки обладнання хімічних виробництв",
        avtor: "Є. Зубцов, І. Кравченко, М. Ожередова",
        body: `Навчальна дисципліна «Розрахунки обладнання хімічних виробництв» належить до переліку
                обов’язкових освітніх компонент з циклу професійної підготовки здобувачів вищої освіти за освітньої-
                професійною програмою «Хімічні технології та інженерія» і спрямована на освоєння комплексу методів та
                підходів вибору і обґрунтування використання обладнання для хімічних виробництв, обґрунтування
                раціональних режимів роботи обладнання, принципів проєктування хімічних виробництв з врахуванням
                економічного контексту та впливу на стан навколишнього природного середовища та здоров’я людей.
                Засвоєння  принципів  проєктування  маловідходних  хімічних  виробництв,  використання
                ресурсозберігаючих технологій сприяє досягненню цілей сталого розвитку – ціль 12 Відповідальне
                споживання, спонукаючи перехід до раціональних моделей виробництв.
                Навчальний посібник містить 5 тематичних частин, зміст яких конкретизовано у 14 темах та 11
                підтемах. Кожна тема містить такі структурні елементи: основні поняття та його визначення, основні
                теоретичні положення, приклади вирішення практичних завдань, практичні завдання, питання для
                самоконтролю, тестові завдання. В навчальному посібнику наведено перелік використаних джерел.
                Посібник призначено для здобувачів вищої освіти ОП «Хімічні технології та інженерія» (рівень
                бакалавр).`,
        src: require("@/assets/b5.jpg"),
        link: "5_book/mobile/index.html",
      },
      {
        id: 6,
        name: "Юридичне дослідження, аналіз та письмо",
        avtor: "Л. Котова",
        body: `Навчальна дисципліна «Юридичне дослідження, аналіз та письмо» спрямована на підготовку
                фахівців-правників, які здатні використовувати знання правових принципів та норм і володіти такими
                здібностями, як: мовні та комунікативні навички, аналітичне й критичне мислення, повага до етичної
                поведінки; на набуття практичних умінь і навичок, у тому числі, збору інформації, аналізу юридичної
                проблеми, побудови юридичної аргументації; складання проєктів юридичних документів.
                Навчальний посібник з дисципліни «Юридичне дослідження, аналіз та письмо» охоплює 8 тем: Вступ
                до дисципліни; Юридична діяльність та практика; Правове дослідження, його методи; Місце правової
                норми в національній, європейській та міжнародній системах; Поняття правової ситуації, її проблематика.
                Правові рішення; Методи розв’язання юридичних задач; Мова права і мова закону. Юридичний текст;
                Особливості юридичних документів. Кожна тема містить ключові поняття, стислі теоретичні відомості,
                практичні завдання, приклади розв’язання задач, а також питання для самоконтролю та тестові завдання.
                До структури посібника також входять джерела (нормативно-правові акти, література та інформаційні
                ресурси), наочні рисунки, схеми та відеоматеріали, які дозволяють здобувачу освіти сформувати більш
                конкретне уявлення щодо питання, яке вивчається та краще засвоїти матеріал.
                Посібник призначено для здобувачів вищої освіти освітньо-професійної програми «Право» (перший
                рівень вищої освіти).`,
        src: require("@/assets/b6.jpg"),
        link: "6_book/mobile/index.html",
      },
      {
        id: 7,
        name: "Методологія проведення та оформлення результатів наукових досліджень у сфері права",
        avtor: "Г. Татаренко",
        body: `Посібник розроблений для викладання обов’язкової навчальної дисципліни «Методологія
                проведення та оформлення результатів наукових досліджень у сфері права» у межах освітньої програми
                третього (освітньо-наукового) рівня вищої освіти «Право» (спеціальність 081 «Право»), яка передбачає
                всебічне ознайомлення та закріплення уявлення про особливості здійснення наукових правових
                досліджень, висвітлює основні положення методології, методики та організації наукової діяльності,
                розвиває навички самостійної науково-дослідної роботи у галузі 081 «Право».
                Посібник побудовано за принципом модульності, містить 13 тем. У межах кожної теми розкрито
                ключові слова, поняття, основні теоретичні положення, питання для самоконтролю, практичні завдання,
                тест, джерела які мають активні лінки та цільові сторінки для конкретної теми. У посібнику використано
                QR-коди з додатковими інформаційними ресурсами, відео. На практичних заняттях здобувачам
                запропоновано пройти онлайн-курс та онлайн лекцію.
                Для студентів, викладачів, аспірантів, докторантів вищих навчальних закладів та всіх, хто цікавиться
                основами методології наукових досліджень.`,
        src: require("@/assets/b7.jpg"),
        link: "7_book/mobile/index.html",
      },
      {
        id: 8,
        name: "Методи прогнозування",
        avtor: "Є. Овчаренко",
        body: `Навчальний посібник "Методи прогнозування" створений для систематизації та передачі знань
                щодо різноманітних методів та підходів у галузі прогнозування. Посібник розглядає теоретичні аспекти
                різних методів прогнозування для розуміння основних прогностичних концепцій та практичні аспекті
                застосування різних методів у реальних сценаріях для ефективного використання цих методів у практичній
                діяльності. Загальна мета посібника – формування у здобувачів освіти комплексного розуміння методів
                прогнозування, а також розвиток їхніх навичок застосування цих методів у реальних умовах.
                Посібник включає в себе розділи, присвячені: основним поняттям прогнозування; первинному
                аналізу даних та ідентифікації наявності тренду; формалізованим та експертним методам прогнозування;
                оцінці якості прогнозів. Кожен розділ містить огляд основних підходів, їхні переваги та обмеження, а також
                приклади використання.
                Навчальний посібник призначений для здобувачів вищої освіти першого (бакалаврського) рівня, що
                навчаються за освітньою програмою "Менеджмент".`,
        src: require("@/assets/b8.jpg"),
        link: "8_book/mobile/index.html",
      },
      {
        id: 9,
        name: "Будівельна механіка",
        avtor: "О. Овчаренко",
        body: `В навчальному посібнику «Будівельна механіка» розглянуті питання забезпечення міцності та
                жорсткості таких поширених конструкцій, як багатопрогонові, статично визначувані балки, ферми, розпірні
                системи. Наведені основні принципи визначення внутрішніх зусиль в конструкціях від дії нерухомих та
                рухомих навантажень. Розглянутий порядок розрахунку конструкцій методом скінченних елементів. Кожна
                тема включає теоретичну частину, питання для самоконтролю, варіанти практичних завдань, приклади їх
                виконання та джерела інформації. В навчальному посібнику розглядається використання систем
                автоматизованого проєктування: PTC Mathcad, ЛІРА-САПР, Ansys Mechanical APDL.
                Навчальний посібник призначений для здобувачів вищої освіти, освітнього ступеня «бакалавр»,
                спеціальності 192 «Будівництво та цивільна інженерія».`,
        src: require("@/assets/b9.jpg"),
        link: "9_book/mobile/index.html",
      },
      {
        id: 10,
        name: "Економічна безпека діяльності підприємств",
        avtor: "В. Вахлакова, М. Плєтньов",
        body: `Навчальна дисципліна «Економічна безпека діяльності підприємств» належить до переліку
                дисциплін з циклу професійної підготовки здобувачів вищої освіти рівня «Магістр» за освітньо-
                професійною програмою «Економіка» і спрямована на надання здобувачам вищої освіти базових знань і
                методів економічного аналізу процесів та явищ, що становлять небезпеку для підприємства; формування
                системних уявлень про закономірності та тенденції розвитку світової і вітчизняної шкіл, визначення та
                складання системи захисту економічної безпеки підприємства, про економічний розвиток методів оцінки
                економічної небезпеки підприємства; визначення наукового внеску окремих шкіл і напрямів в економічну
                теорію та їхнього впливу на економічну безпеку держави; вироблення сучасного економічного мислення
                та світогляду здобувачів вищої освіти щодо систем захисту підприємства.
                Навчальний посібник «Економічна безпека діяльності підприємств» призначений для здобувачів
                вищої освіти ОП «Економіка» (рівень магістр) та інших освітніх програм, викладачів закладів вищої освіти.`,
        src: require("@/assets/b10.jpg"),
        link: "10_book/mobile/index.html",
      },
      {
        id: 11,
        name: "Обґрунтування господарських рішень і оцінювання ризиків",
        avtor: "І. Семененко, О. Ольшанський, К. Сєрєбряк, О. Маслош",
        body: ` 
                Посібник  розкриває  ключові  аспекти  прийняття  господарських  рішень  та  управління  ризиками  в 
                сучасних умовах. 
                Частина  1  "Практичні  заняття"  містить  розділи  з  основних  теоретичних  положень,  завдання  для 
                аудиторної та самостійної роботи, питання для самоконтролю та джерела кожної теми.  
                Частина  2  "Ситуаційні  вправи"  надає  можливість  здобувачам  застосовувати  здобуті  знання  у 
                практичних сценаріях.  
                Посібник сприяє формуванню студентами компетентностей, необхідних для успішної роботи у галузі 
                управління та ефективного прийняття господарських рішень. 
                Посібник "Обґрунтування господарських рішень і оцінювання ризиків" призначений для здобувачів 
                першого  (бакалаврського)  рівня  за  освітньо-професійними  програмами  051  "Економіка"  і  076 
                "Підприємництво, торгівля та біржова діяльність".`,
        src: require("@/assets/b11.jpg"),
        link: "11_book/mobile/index.html",
      },
      {
        id: 12,
        name: "MICROSOFT OFFICE",
        avtor: "С. Мічківський, Д. Балдик, В. Головань",
        body: `
                Навчальний  посібник  призначено  для  здобувачів  вищої  освіти  (рівень  бакалавра).  Матеріали 
                посібника дозволяють ознайомитися з сучасними офісними пакетами; оволодіти знаннями та навичками 
                використання  пакету  Microsoft  Office,  зокрема  з  роботи  з  текстовим  редактором  (Word),  електронними 
                таблицями  (Excel),  електронною  поштою  (Outlook),  створенням  презентацій  (PowerPoint),  використання 
                комунікаційних  програм  (Teams);  оволодіння  навичками  роботи  в  Інтернет;  формування  культури  та 
                загальних  вимог  до  роботи  з  документами  (як  на  папері,  так  і  в  електронному  вигляді);  оволодіння 
                навичками обробки та аналізу даних з використанням персонального комп’ютера. 
                Посібник  розроблений  для  викладання  вибіркової  навчальної  дисципліни  "Microsoft  Office  (Word, 
                Excel,  Outlook  …)"  у  межах  освітньої  програми  бакалаврського  рівня  "072  Фінанси,  банківська  справа  та 
                страхування (ОПП "Фінанси, банківська справа та страхування")" та інших освітніх програм.  
                `,
        src: require("@/assets/b12.jpg"),
        link: "12_book/mobile/index.html",
      },
      {
        id: 13,
        name: "Теорія організації ",
        avtor: "О. Орлова-Курилова",
        body: ` 
                Навчальний посібник присвячено актуальним питанням теорії організації, тому що організації стають 
                не тільки основними суб’єктами економічної діяльності, але й визначальними агентами для формування 
                соціокультурного середовища. Глибоке розуміння структури організацій, їх взаємодії та впливу на соціум є 
                важливим для ефективного управління та досягнення стратегічних цілей. 
                У посібнику викладено теоретичні та методологічні основи теорії організації. Акцентовано увагу на 
                широкому  спектрі  тем,  що  дозволяє  зануритися  у  світ  знань  про  організації.  Важливі  аспекти 
                розглядаються у наступних  темах: методологічні засади теорії  організації; основні організаційні теорії та 
                моделі; організація як система; організація як соціум; організаційний процес; самоорганізація; зовнішнє та 
                внутрішнє середовище організації; організаційне проєктування; культура організації. 
                Для якісного засвоєння навчального матеріалу в посібнику за кожною темою запропоновані: поняття 
                та  його  визначення;  основні  теоретичні  положення;  список  використаних  джерел;  наведені  приклади; 
                питання для самоконтролю; практичні завдання; тести. Навчальний посібник містить багато посилань на 
                інтерактивні ресурси для засвоєння матеріалу та знайомства з передовим досвідом сучасних організації.  
                Навчальний посібник з теорії організації є комплексним інструментом для здобувачів вищої освіти 
                третього  освітньо-наукового  рівня,  які  працюють  над  здобуттям  наукового  ступеня  Доктора  філософії  в 
                галузі «Менеджмент». Водночас, він пропонує інтригуючий зміст для всіх, хто самостійно поглиблює свої 
                знання, цікавиться будовою організацій та їх особливостями. 
                `,
        src: require("@/assets/b13.jpg"),
        link: "13_book/mobile/index.html",
      },
      {
        id: 14,
        name: "Основи менеджменту та підприємництва",
        avtor: "Н. Ткаченко",
        body: `                  
                Навчальний посібник надає можливість опанувати обсяг спеціальних знань в галузі теорії і практики 
                сучасного менеджменту та сформувати уміння і навички сучасного управлінського мислення. 
                У  вигляді  основних  теоретичних  положень  в  концентрованій  формі  студентам  подається  вміст  9 
                основних  тем  з  основ  менеджменту,  кожна  з  яких  містить  ключові  визначення,  блок  питань  для 
                самоперевірки  знань  і  повторення  матеріалу,  практичні  завдання  та  ситуаційні  вправи.  Також  для 
                самоперевірки  надається  тест,  який  можна  використовувати  для  проведення  підсумкового  контролю 
                знань студентів та самоконтролю.  
                У  посібнику  висвітлено  історію  розвитку  менеджменту,  розглянуто  сутність  та  призначення 
                менеджменту,  охарактеризовано  працю  менеджера,  ролі,  навички  та  специфіку  роботи  менеджерів, 
                репрезентовано  базові  функції  менеджменту  –  планування,  організацію,  мотивацію,  контроль  та 
                висвітлено основні питання, пов’язані з прийняттям управлінських рішень і комунікацією в менеджменті, 
                лідерством, владою та стилями управління.  
                Посібник  адресований  студентам  вищих  навчальних  закладів,  які  навчаються  за  освітньо-
                кваліфікаційним рівнем бакалавра за спеціальністю «Менеджмент», а також може бути корисним у роботі 
                викладачів і фахівців з менеджменту, керівників та підприємців. 
                `,
        src: require("@/assets/b14.jpg"),
        link: "14_book/mobile/index.html",
      },
    ],
  },
  getters: {
    allBooks(state) {
      return state.books;
    },
    filtrBooks(state, getters) {
      if (state.vFiltr.length) {
        return state.books
          .filter(
            (book) =>
              book.name.includes(state.vFiltr) ||
              book.body.includes(state.vFiltr)
          )
          .map((book) => {
            return {
              ...book,
              body: book.body.replace(
                new RegExp(state.vFiltr, "g"),
                `<span class="keyword">${state.vFiltr}</span>`
              ),
              name: book.name.replace(
                new RegExp(state.vFiltr, "g"),
                `<span class="keyword">${state.vFiltr}</span>`
              ),
            };
          });
      } else {
        return getters.allBooks;
      }
    },
  },
};
